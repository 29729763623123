import React from 'react';
import Header from "../../components/Header/Header";
import {useUser} from "../../auth/useUser";
import Loader from "../../components/Loader/Loader";
import {useNavigate} from "react-router-dom";
import {IonIcon} from "@ionic/react";
import {copyOutline, logOutOutline} from "ionicons/icons";
import {enqueueSnackbar} from "notistack";
import {clearCache} from "../../api/cache";
import {useMutation, useQueryClient} from "@tanstack/react-query";

function Account(): JSX.Element {
    const queryClient = useQueryClient();
    const {user} = useUser();
    const navigate = useNavigate();

    const clearCacheMutation = useMutation({
        mutationFn: () => clearCache(queryClient),
        onSuccess: (data: number) => {
            console.log(data + " server-side cache items cleared");
            enqueueSnackbar("Cache cleared", {variant: "success"});
        },
        retry: 3,
    });

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            enqueueSnackbar("Copied to clipboard", {variant: "success"});
        }).catch((err) => {
            enqueueSnackbar("Could not copy to clipboard", {variant: "error"});
        });
    }

    return (
        <>
            <Header title={"Account"} rightIcon={logOutOutline} rightModalId={"confirm-logout"}/>
            {!user ?
                <Loader show={true}/>
                :
                <>
                    <div className="listview-title mt-1">Account details</div>
                    <ul className="listview simple-listview text inset">
                        <li>
                            <div>User ID</div>
                            <div style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
                                <span>{user.id}</span>
                                <span style={{paddingTop: "4px"}}><IonIcon icon={copyOutline}
                                                                           className={"pointer text-primary"}
                                                                           onClick={() => copyToClipboard(user.id)}/></span>
                            </div>
                        </li>
                        <li>
                            <div>Account created</div>
                            <div style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
                                <span>{user.createdAt.split("T")[0]}</span>
                            </div>
                        </li>
                    </ul>
                    <div className="listview-title mt-1">Debugging options</div>
                    <ul className="listview simple-listview text inset mb-2">
                        <li style={{cursor: "pointer"}} onClick={() => clearCacheMutation.mutate()}>
                            <div className="in">
                                <div>
                                    Clear cache
                                    <footer>
                                        <small>Clear the cache to force a reload of the data</small>
                                    </footer>
                                </div>
                            </div>
                            {clearCacheMutation.isPending &&
                                <span className="spinner-border spinner-border-md" role="status" aria-hidden="true"/>}
                        </li>
                    </ul>
                </>
            }
            <div className="modal fade dialogbox" id="confirm-logout" data-bs-backdrop="static" tabIndex={-1}
                 style={{display: "none"}} aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content text-dark">
                        <div className="modal-header">
                            <h5 className="modal-title">Logout</h5>
                        </div>
                        <div className="modal-body">
                            Are you sure?
                        </div>
                        <div className="modal-footer">
                            <div className="btn-inline">
                                <button className="btn btn-text-secondary" data-bs-dismiss="modal">CANCEL</button>
                                <button className="btn btn-text-primary" data-bs-dismiss="modal"
                                        onClick={() => navigate("/logout")}>LOGOUT
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Account;
